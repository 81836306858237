<template>
  <v-card class="segmented mt-4" flat>
    <v-card-title>
      <h5>{{ $t('feedbacks.processTypes_long.2') }}</h5>

      <template v-if="!readOnly">
        <v-spacer></v-spacer>
        <div class="d-block d-md-flex">
          <v-btn
            small
            class="ml-md-9 dowload-pdp-template"
            href="https://www.feedbackweb.de/UP/DPT/Downloads/PDP_TEMPLATE_v3.1_DE.docx"
          >
            <v-icon small class="mr-2">mdi-file-download-outline</v-icon>
            {{ $t('pdp.downloadTemplate.de') }}
          </v-btn>

          <v-btn
            small
            class="ml-md-2 mt-2 mt-md-0 dowload-pdp-template"
            href="https://www.feedbackweb.de/UP/DPT/Downloads/PDP_TEMPLATE_v2.6_EN.docx"
          >
            <v-icon small class="mr-2">mdi-file-download-outline</v-icon>
            {{ $t('pdp.downloadTemplate.en') }}
          </v-btn>
        </div>
      </template>
    </v-card-title>
    <v-card-text>
      <pdp-table
        :pdpData="pdpData"
        :loading="pdpLoading"
        @reviewDateSelected="saveReview"
      ></pdp-table>
    </v-card-text>
    <v-card-actions v-if="!readOnly" class="pa-4 d-block">
      <v-row dense>
        <v-col cols="12" class="muted text-uppercase">
          {{ $t('pdp.addDocument') }}
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12" md="6">
          <v-file-input
            v-model="newPdpFile"
            :placeholder="$t('chooseFile')"
            prepend-icon="mdi-paperclip"
            outlined
            dense
            hide-details
          >
            <template v-slot:selection="{ text }">
              <v-chip small label color="primary">{{ text }}</v-chip>
            </template>
          </v-file-input>
        </v-col>
        <v-col cols="12" md="6">
          <v-btn
            class="secondary"
            :disabled="!newPdpFile"
            :loading="pdpUploading"
            :block="blockButtons"
            @click="startPdpUpload"
          >
            {{ $t('uploadFile') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex';
import { v4 as uuid } from 'uuid';

import pdpTable from '@/components/feedbacks/pdp/pdp-table.vue';

export default {
  props: {
    userId: {
      type: String,
      required: false,
      default: null
    },

    readOnly: {
      type: Boolean,
      required: false,
      default: false
    },

    mode: {
      type: String,
      required: false,
      default: 'employee'
    }
  },

  data() {
    return {
      pdpData: null,
      pdpLoading: true,
      newPdpFile: null,
      pdpUploading: false,
      pdpUploadCorrelationId: null
    };
  },

  computed: {
    blockButtons() {
      return this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm;
    },

    nextReviewDate() {
      if (this.pdpData) {
        return this.pdpData.nextReview;
      }

      return null;
    }
  },

  watch: {
    async userId() {
      if (this.mode === 'supervisor' && this.userId !== null) {
        this.pdpData = await this.getPdpDataAsSupervisor(this.userId);
        this.pdpLoading = false;
      }
    },

    nextReviewDate() {
      this.setNextReview(this.nextReviewDate);
    }
  },

  async mounted() {
    if (this.mode === 'employee') {
      this.pdpData = await this.getPdpData();
      this.pdpLoading = false;
    } else if (this.mode === 'supervisor') {
      this.pdpData = await this.getPdpDataAsSupervisor(this.userId);
      this.pdpLoading = false;
    }
  },

  beforeDestroy() {
    if (this.pdpUploadCorrelationId !== null) {
      this.$processOverviewHub.leaveGroup(this.pdpUploadCorrelationId);
      this.$processOverviewHub.$off(
        'pdp-document-saved',
        this.pdpUploadComplete
      );
    }
  },

  methods: {
    ...mapActions({
      setNextReview: 'pdp/setNextReview',
      getPdpData: 'pdp/getAsRecipient',
      getPdpDataAsSupervisor: 'pdp/getAsSupervisor',
      uploadPdpDocument: 'pdp/uploadDocument'
    }),

    async addReview() {
      if (!this.reviewDateFormatted) {
        return;
      }

      this.addPdpReview(this.reviewDateFormatted);
      this.reviewDate = null;
      this.reviewDateFormatted = null;

      this.pdpData = null;
      this.pdpLoading = true;

      setTimeout(async () => {
        this.pdpData = await this.getPdpData();
        this.pdpLoading = false;
      }, 1000);
    },

    formatDate(unformattedDate) {
      if (unformattedDate === null) {
        return null;
      }

      const [year, month, day] = unformattedDate.split('-');
      const date = new Date(year, month - 1, day);
      return date.toISOString();
    },

    pdpUploadComplete(addedDocument) {
      this.pdpUploading = false;
      this.newPdpFile = null;

      if (this.pdpData.latestDocument !== null) {
        this.pdpData.revisions.push(this.pdpData.latestDocument);
      }

      this.pdpData.isProcessCreated = true;
      this.pdpData.lastUpload = addedDocument.uploadDateUtc;
      this.pdpData.latestDocument = {
        downloadUrl: addedDocument.downloadUrl,
        fileName: addedDocument.fileName,
        uploadDateUtc: addedDocument.uploadDateUtc
      };
    },

    saveReview(reviewDate) {
      const formattedDate = this.formatDate(reviewDate);

      if (!formattedDate) {
        return;
      }

      this.pdpData.nextReview = formattedDate;
    },

    async startPdpUpload() {
      // if the correlation id has been created then the queue
      // has already been joined.
      if (this.pdpUploadCorrelationId === null) {
        this.pdpUploadCorrelationId = uuid();
        this.$processOverviewHub.joinGroup(this.pdpUploadCorrelationId);
        this.$processOverviewHub.$on(
          'pdp-document-saved',
          this.pdpUploadComplete
        );
      }

      this.pdpUploading = true;
      await this.uploadPdpDocument(this.newPdpFile);
    }
  },

  components: {
    pdpTable
  }
};
</script>

<style lang="scss" scoped>
.theme--light a {
  text-decoration: none;
  color: rgb(34, 46, 62);
}

.theme--light a.download-pdp-template,
.theme--light a.download-pdp-template:visited {
  color: rgb(34, 46, 62);
}
</style>
