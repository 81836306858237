<template>
  <div>
    <v-card class="segmented" flat>
      <v-card-title>
        <v-row>
          <v-col cols="12">
            <h5>{{ $t('feedbacks.myFeedbacks') }}</h5>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <feedbacks-table
          :feedbacks="currentFeedbacks"
          :loading="loading"
          :employeeColumn="'donor'"
          :expanded="true"
          @process-created="loadFeedbacks"
        ></feedbacks-table>
      </v-card-text>
    </v-card>

    <pdp-overview
      v-if="this.currentUser !== null && this.currentUser.hasPdpProcess"
    ></pdp-overview>

    <v-card class="segmented mt-6" flat v-if="hasArchive">
      <v-card-title>
        <v-row>
          <v-col cols="12">
            <h5>{{ $t('feedbacks.archive') }}</h5>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <archive-table
          :feedbacks="archivedFeedbacks"
          :loading="loading"
          :employeeColumn="'donor'"
          :expanded="false"
          :isArchive="true"
        ></archive-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { v4 as uuid } from 'uuid';
import { feedbackEnums } from '@/enums/feedbacks.js';

import feedbacksTable from '@/components/feedbacks/feedbacks-table.vue';
import archiveTable from '@/components/feedbacks/archive-table.vue';
import pdpOverview from '@/components/feedbacks/pdp/pdp-overview.vue';

export default {
  data() {
    return {
      feedbacks: null,
      currentFeedbacks: [],
      archivedFeedbacks: [],
      pdpData: null,
      loading: true,
      pdpLoading: true,
      newPdpFile: null,
      pdpUploading: false,
      pdpUploadCorrelationId: null,
      reviewDate: null,
      reviewDatePicker: false,
      reviewDateFormatted: null
    };
  },

  computed: {
    ...mapGetters({
      currentUser: 'currentUser'
    }),

    hasArchive() {
      return (
        this.archivedFeedbacks !== null && this.archivedFeedbacks.length > 0
      );
    }
  },

  async mounted() {
    this.$usersHub.$on('recipient-processes-updated', this.loadFeedbacks);
    await this.loadFeedbacks();
  },

  beforeDestroy() {
    this.$usersHub.$off('recipient-processes-updated', this.loadFeedbacks);
  },

  methods: {
    ...mapActions({
      listFeedbacks: 'feedbacks/listMyFeedbacks',
      importArchive: 'feedbacks/importArchive'
    }),

    ...mapMutations({
      clearFeedbacks: 'feedbacks/clearFeedbacks'
    }),

    async loadExternalArchive() {
      if (!this.currentUser) {
        return;
      }

      const archiveData = await this.importArchive(this.currentUser.shorthand);

      if (archiveData.isAvailable) {
        this.feedbacks.push({
          customArchive: true,
          archived: true,
          processType: feedbackEnums.processTypes.MAFEG,
          statusId: 170,
          startDate: archiveData.startDate,
          endDate: archiveData.endDate,
          donorFirstName: archiveData.donorFirstname,
          donorLastName: archiveData.donorLastname,
          talkDate: archiveData.talkDate,
          feedbacks: [
            {
              id: uuid(),
              feedbackType: feedbackEnums.feedbackTypes.SELF_EVALUATION,
              submitted: true,
              approved: true,
              customArchive: true,
              customUrl: archiveData.selfEvaluationUrl,
              canRead: true
            },
            {
              id: uuid(),
              feedbackType: feedbackEnums.feedbackTypes.MANAGER_EVALUATION,
              submitted: true,
              approved: true,
              customArchive: true,
              customUrl: archiveData.supervisorEvaluationUrl,
              canRead: true
            },
            {
              id: uuid(),
              feedbackType: feedbackEnums.feedbackTypes.DOCUMENTATION,
              submitted: true,
              approved: true,
              customArchive: true,
              customUrl: archiveData.conclusionUrl,
              canRead: true
            },
            {
              id: uuid(),
              feedbackType: feedbackEnums.feedbackTypes.COMPARISON,
              submitted: true,
              approved: true,
              customArchive: true,
              customUrl: archiveData.comparisonUrl,
              canRead: true
            }
          ]
        });
      }
    },

    async loadFeedbacks() {
      this.loading = true;
      this.clearFeedbacks();
      this.feedbacks = await this.listFeedbacks();

      await this.loadExternalArchive();

      this.currentFeedbacks = this.feedbacks.filter((x) => !x.archived);
      this.archivedFeedbacks = this.feedbacks.filter((x) => x.archived);
      this.loading = false;
    }
  },

  components: {
    archiveTable,
    feedbacksTable,
    pdpOverview
  }
};
</script>
