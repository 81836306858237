<template>
  <v-row>
    <v-col cols="12" md="1">
      <p
        class="d-xs-block d-md-none ma-0 caption text-uppercase grey--text text--darken-1"
      >
        <strong>{{ $t('feedbacks.type') }}</strong>
      </p>
      <strong>{{
        $t(`feedbacks.processTypes.${feedback.processType}`)
      }}</strong>
    </v-col>

    <v-col cols="12" md="3">
      <p
        class="d-xs-block d-md-none ma-0 caption text-uppercase grey--text text--darken-1"
      >
        <strong>{{ $t('feedbacks.status') }}</strong>
      </p>

      <v-chip
        label
        :color="getStatusColor(feedback.statusId, feedback.processType)"
      >
        {{ localizeStatus(feedback.processType, feedback.statusId) }}
      </v-chip>
    </v-col>

    <v-col cols="12" md="3">
      <p
        class="d-xs-block d-md-none ma-0 caption text-uppercase grey--text text--darken-1"
      >
        <strong>{{ $t('feedbacks.processPartner') }}</strong>
      </p>

      <template v-if="employeeColumn === 'recipient'">
        {{ feedback.recipientFirstName }} {{ feedback.recipientLastName }}
      </template>
      <template v-else>
        {{ feedback.donorFirstName }} {{ feedback.donorLastName }}
      </template>
    </v-col>

    <v-col cols="12" md="4">
      <template>
        <p
          class="d-xs-block d-md-none ma-0 caption text-uppercase grey--text text--darken-1"
        >
          <strong>{{ $t('feedbacks.talkDate') }}</strong>
        </p>

        <template v-if="talkDate">
          {{ $d(Date.parse(talkDate), 'datetime') }}
        </template>
        <i v-else class="muted">
          {{ $t('feedbacks.noTalkDateAvailable') }}
        </i>
      </template>
    </v-col>

    <v-col
      cols="12"
      md="1"
      @click="itemExpanded = !itemExpanded"
      class="expandable d-flex justify-center"
    >
      <v-icon>{{ expanderIcon }}</v-icon>
    </v-col>

    <v-expand-transition>
      <v-col
        cols="12"
        v-show="itemExpanded"
        class="expanded-content"
        :class="initializingClass"
      >
        <v-row v-if="showProcessTimeSpan">
          <v-col cols="12">
            <div class="ma-0 d-flex align-center muted">
              <strong class="mr-1">{{ $t('feedbacks.timespan') }}:</strong>
              <template v-if="!feedback.customArchive">
                {{ $d(new Date(feedback.startDate), 'date') }} &ndash;
                {{ $d(new Date(feedback.endDate), 'date') }}
              </template>
              <template v-else>
                {{ feedback.startDate }} &ndash; {{ feedback.endDate }}
              </template>
            </div>
          </v-col>
        </v-row>

        <v-row
          v-for="item in feedback.feedbacks"
          :key="`sub_feedback_${item.id}`"
        >
          <v-col cols="12" md="2" class="py-1 py-md-3">
            <strong>{{ feedbackTitle(item) }}</strong>
          </v-col>

          <v-col cols="12" md="2" class="py-1 py-md-3">
            <v-chip v-if="!item.submitted" label color="orange">
              {{ $t('feedbacks.notSubmitted') }}
            </v-chip>
            <v-chip
              v-else-if="item.submitted && item.canUpdate"
              label
              color="blue"
            >
              {{ $t('feedbacks.inProgress') }}
            </v-chip>
            <v-chip
              v-else-if="item.submitted && !item.approved"
              label
              color="green"
            >
              {{ $t('feedbacks.submitted') }}
            </v-chip>
            <v-chip
              v-else-if="item.submitted && item.approved"
              label
              color="green"
            >
              {{ $t('feedbacks.approved') }}
            </v-chip>
          </v-col>

          <v-col cols="12" md="3" class="py-1 py-md-3">
            <v-btn
              v-if="!feedback.customArchive"
              small
              text
              :to="{ name: 'FeedbackForm', params: { id: item.id } }"
              :disabled="!item.canRead"
              :target="openInTab ? '_blank' : '_self'"
              class="px-2"
            >
              <v-icon>mdi-clipboard-text</v-icon>
            </v-btn>

            <v-btn
              v-if="
                !feedback.customArchive ||
                feedback.customUrl === null ||
                feedback.customUrl === ''
              "
              small
              text
              :disabled="!item.canRead || !item.submitted"
              :to="{ name: 'PrintFeedback', params: { id: item.id } }"
              target="_blank"
              class="px-2"
            >
              <v-icon>mdi-printer</v-icon>
            </v-btn>

            <v-btn
              v-else
              small
              text
              :disabled="!item.canRead || !item.submitted"
              :href="item.customUrl"
              target="_blank"
              class="px-2"
            >
              <v-icon>mdi-printer</v-icon>
            </v-btn>

            <v-btn
              v-if="isPdcProcess"
              small
              text
              :disabled="!item.canRead || !item.submitted"
              class="px-2"
              target="_blank"
              @click="
                download(
                  getPdcProcessDownloadUrl(item.id),
                  getPdcProcessDownloadFileName(),
                  'text/html'
                )
              "
            >
              <v-icon>mdi-file-powerpoint</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="8" md="5" class="d-flex justify-end"> </v-col>
        </v-row>
      </v-col>
    </v-expand-transition>
  </v-row>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import api from '@/helpers/api.js';
import processMixins from '@/mixins/feedback-process.js';
import { feedbackEnums } from '@/enums/feedbacks.js';

export default {
  props: {
    feedback: {
      type: Object,
      required: true
    },

    employeeColumn: {
      type: String,
      required: true
    },

    expanded: {
      type: Boolean,
      required: false,
      default: false
    },

    keyword: {
      type: String,
      required: false,
      default: ''
    },

    openInTab: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  mixins: [processMixins],

  data() {
    return {
      itemExpanded: false,
      showDatetimePicker: false,
      talkDate: null,
      initializingClass: 'initializing',
      showAddTalkdateButton: false,
      showEditTalkdateButton: false,
      statusOptions: [],

      // Flag to display or hide the time span of the process.
      // This might need to be re-enabled via organization or
      // process settings in the future so I'll hide it behind a
      // flag instead of removing the markup.
      showProcessTimeSpan: false
    };
  },

  watch: {
    showDatetimePicker() {
      if (!this.showDatetimePicker) {
        this.$refs.dateTimePicker.reset();
      }
    }
  },

  computed: {
    ...mapGetters({
      getProcessCreators: 'workflows/getProcessCreators'
    }),

    expanderIcon() {
      if (this.itemExpanded) {
        return 'mdi-chevron-up';
      } else {
        return 'mdi-chevron-down';
      }
    },

    feedbackItems() {
      return this.feedback.feedbacks.filter(
        (x) => !this.feedback.customFeedback || x.customUrl !== ''
      );
    },

    isPdcProcess() {
      return (
        this.feedback.processType === feedbackEnums.processTypes.PDC ||
        this.feedback.processType === feedbackEnums.processTypes.EDC
      );
    }
  },

  async mounted() {
    this.talkDate = this.feedback.talkDate;
    this.itemExpanded = this.expanded;

    // remove the initializing class after a small delay. This
    // way we can disable the transition at the initial load
    // in case the componend is used in the expanded state.
    // Otherwise the initial expanding of the items would
    // already use the transition and that looks weird.
    setTimeout(() => {
      this.initializingClass = '';
    }, 300);
  },

  methods: {
    ...mapActions({
      getStatusOptions: 'feedbacks/listFeedbackStatusOptions'
    }),

    async download(filePath, fileName, mimeType) {
      var file = await api.download(filePath);
      const blob = new Blob([file], {
        type: mimeType
      });

      const fileUrl = window.URL.createObjectURL(blob);

      const anchor = document.createElement('a');
      document.body.appendChild(anchor);
      anchor.href = fileUrl;
      anchor.download = fileName;
      anchor.click();

      window.URL.revokeObjectURL(fileUrl);
    },

    getPdcProcessDownloadUrl(feedbackId) {
      return `/download/pdc/${feedbackId}`;
    },

    getPdcProcessDownloadFileName() {
      if (this.feedback.processType === feedbackEnums.processTypes.EDC) {
        return 'EDC.html';
      } else {
        return 'PDC.html';
      }
    },

    getStatusOption(processId, statusId) {
      const option = this.statusOptions.find(
        (x) => x.processType === processId && x.statusId === statusId
      );
      if (option) {
        return this.localize(option.translations);
      }
      return '';
    },

    feedbackTitle(item) {
      if (item.title?.length > 0 ?? false) {
        return this.localize(item.title);
      }
      return this.$t(`feedbacks.typeOptions.${item.feedbackType}`);
    }
  }
};
</script>

<style lang="scss" scoped>
.overview-body .row .expandable:hover {
  cursor: pointer;
}

.overview-body .row.table-row-divider:hover {
  background: none;
}

.table .row.table-row-divider {
  margin-top: 0;
}

.table .row.table-row-divider > div {
  padding-left: 0;
  padding-right: 0;
  padding: 0;
}

.expanded-content {
  background-color: var(--element-background-secondary);
  // disable all transitions during the initializing phase
  &.initializing {
    transition: none !important;
  }

  .row {
    align-items: center;
  }

  .row:after {
    display: block;
    content: ' ';
    width: 100%;
    margin-left: 1em;
    margin-right: 1em;
    border-bottom: 2px dotted rgba(0, 0, 0, 0.1);
  }

  .row:last-of-type:after {
    border-bottom: none;
  }
}
</style>
