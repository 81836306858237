<template>
  <div>
    <br />

    <v-row v-if="searchable" class="mb-4">
      <v-col cols="12" md="6">
        <v-select
          v-model="statusFilter"
          :items="statusOptions"
          multiple
          chips
          solo
          flat
          hide-details
          clearable
          :label="$t('feedbacks.filterByStatus')"
        >
        </v-select>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          v-model="searchKeyword"
          solo
          flat
          hide-details
          :label="$t('searchKeywordPlaceholder')"
          append-icon="mdi-magnify"
        ></v-text-field>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <div class="table">
          <div class="table-header">
            <v-row class="d-none d-md-flex">
              <v-col cols="12" md="1" class="caption">{{
                $t('feedbacks.type_short')
              }}</v-col>

              <v-col cols="12" md="3" class="caption">{{
                $t('feedbacks.status')
              }}</v-col>

              <v-col cols="12" md="3" class="caption">
                {{ $t('feedbacks.processPartner') }}
              </v-col>

              <v-col cols="12" md="4" class="caption">{{
                $t('feedbacks.talkDate')
              }}</v-col>

              <v-col cols="12" md="1"></v-col>
            </v-row>
          </div>

          <div
            v-if="feedbacks && !loading && feedbacks.length > 0"
            class="overview-body table-body"
          >
            <template v-for="feedback in visibleFeedbacks">
              <feedback-item
                :feedback="feedback"
                :key="`feedback_table_item_${feedback.id}`"
                :employeeColumn="employeeColumn"
                :expanded="expanded"
                :keyword="searchKeyword"
                :openInTab="openInTab"
              ></feedback-item>
            </template>
          </div>
          <div v-else-if="loading" class="mt-4">
            <v-row>
              <v-col cols="12">
                <v-progress-linear indeterminate></v-progress-linear>
              </v-col>
            </v-row>
          </div>
          <div v-else class="mt-4">
            <v-row>
              <v-col cols="12">
                <v-alert text type="info">
                  {{ $t('feedbacks.noActiveProcess') }}
                </v-alert>
              </v-col>
            </v-row>
          </div>
        </div>
      </v-col>
    </v-row>

    <v-row v-show="numPages > 1">
      <v-col cols="12">
        <div class="text-center">
          <v-pagination
            v-model="pageIndex"
            :length="numPages"
            :total-visible="7"
          ></v-pagination>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import feedbackItem from '@/components/feedbacks/archive-table-item';

export default {
  props: {
    feedbacks: {
      type: Array,
      required: false,
      default: () => []
    },

    employeeColumn: {
      type: String,
      required: false,
      default: 'recipient'
    },

    loading: {
      type: Boolean,
      required: false,
      default: false
    },

    expanded: {
      type: Boolean,
      required: false,
      default: false
    },

    searchable: {
      type: Boolean,
      required: false,
      default: false
    },

    isArchive: {
      type: Boolean,
      required: false,
      default: false
    },

    openInTab: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data: () => ({
    pageIndex: 1,
    itemsPerPage: 15,
    searchKeyword: '',
    statusFilter: null
  }),

  computed: {
    numPages() {
      return Math.ceil(this.sortedFeedbacks.length / this.itemsPerPage);
    },

    filteredFeedbacks() {
      const kw = this.searchKeyword.toLowerCase();
      const states = this.statusFilter?.map((x) => x.statusId);
      const types = this.statusFilter?.map((x) => x.processType);

      if (kw === '' && !states) {
        return this.feedbacks;
      }

      return this.feedbacks.filter((x) => {
        if (
          states &&
          states.length > 0 &&
          (!states.includes(x.statusId) || !types.includes(x.processType))
        ) {
          return false;
        }

        if (x.recipientFirstName.toLowerCase().indexOf(kw) > -1) {
          return true;
        }

        if (x.recipientLastName.toLowerCase().indexOf(kw) > -1) {
          return true;
        }

        return false;
      });
    },

    sortedFeedbacks() {
      if (
        this.filteredFeedbacks === null ||
        this.filteredFeedbacks.length === 0
      ) {
        return [];
      }

      return [...this.filteredFeedbacks].sort((a, b) => {
        if (a.startDate < b.startDate) {
          return 1;
        } else if (a.startDate > b.startDate) {
          return -1;
        } else {
          return 0;
        }
      });
    },

    statusOptions() {
      if (!this.feedbacks) {
        return [];
      }

      const options = [
        ...new Set(
          this.feedbacks.map((x) => ({
            processType: x.processType,
            statusId: x.statusId
          }))
        )
      ];
      return options.map((x) => {
        return {
          value: x,
          text: this.localizeStatus(x.processType, x.statusId)
        };
      });
    },

    visibleFeedbacks() {
      const startItem = (this.pageIndex - 1) * this.itemsPerPage;
      const endItem = Math.min(
        startItem + this.itemsPerPage,
        this.sortedFeedbacks.length
      );
      return this.sortedFeedbacks.slice(startItem, endItem);
    }
  },

  components: {
    feedbackItem
  }
};
</script>

<style scoped lang="scss">
.table {
  margin-left: -3px;
  margin-right: -3px;
}

.table strong {
  color: #000;
}
</style>
