<template>
  <v-row class="feedback-row" :class="{ 'bottom-border': itemExpanded }">
    <v-col cols="12" md="1">
      <p
        class="d-xs-block d-md-none ma-0 caption text-uppercase grey--text text--darken-1"
      >
        <strong>{{ $t('feedbacks.type') }}</strong>
      </p>
      <strong>{{
        $t(`feedbacks.processTypes.${feedback.processType}`)
      }}</strong>
    </v-col>

    <v-col cols="12" :md="employeeColumn === 'recipient' ? 2 : 3">
      <p
        class="d-xs-block d-md-none ma-0 caption text-uppercase grey--text text--darken-1"
      >
        <strong>{{ $t('feedbacks.status') }}</strong>
      </p>

      <v-chip
        label
        :color="getStatusColor(feedback.statusId, feedback.processType)"
      >
        {{ localizeStatus(feedback.processType, feedback.statusId) }}
      </v-chip>
    </v-col>

    <v-col cols="12" :md="employeeColumn === 'recipient' ? 2 : 3">
      <p
        class="d-xs-block d-md-none ma-0 caption text-uppercase grey--text text--darken-1"
      >
        <strong>{{ $t('feedbacks.processPartner') }}</strong>
      </p>

      <template v-if="employeeColumn === 'recipient'">
        <span
          v-html="$options.filters.highlight(feedback.recipientName, keyword)"
        ></span>
      </template>
      <template v-else>
        <span
          v-html="$options.filters.highlight(feedback.donorName, keyword)"
        ></span>
      </template>
    </v-col>

    <v-col v-if="employeeColumn === 'recipient'" cols="12" md="3">
      <p
        class="d-xs-block d-md-none ma-0 caption text-uppercase grey--text text--darken-1"
      >
        <strong>{{ $t('employeeDetails.supervisor') }}</strong>
      </p>

      <template v-if="feedback.mentorAvailable">
        <span
          v-html="$options.filters.highlight(feedback.mentorName, keyword)"
        ></span>

        <div class="mt-2">
          <v-icon small class="mr-1">mdi-account</v-icon>
          {{ $t('feedbacks.mentor') }}:
          <span
            v-html="$options.filters.highlight(feedback.donorName, keyword)"
          ></span>
        </div>
      </template>
      <template v-else>
        <span
          v-html="$options.filters.highlight(feedback.donorName, keyword)"
        ></span>
      </template>
    </v-col>

    <v-col cols="12" :md="employeeColumn === 'recipient' ? 3 : 4">
      <p
        class="d-xs-block d-md-none ma-0 caption text-uppercase grey--text text--darken-1"
      >
        <strong>{{ $t('feedbacks.nextStep') }}</strong>
      </p>
      {{ localizeStatusDescription(feedback.processType, feedback.statusId) }}

      <div class="mt-4" v-if="feedback.mentorAvailable"></div>
    </v-col>

    <v-col
      cols="12"
      md="1"
      @click="itemExpanded = !itemExpanded"
      class="expandable d-flex justify-center"
    >
      <v-icon>{{ expanderIcon }}</v-icon>
    </v-col>

    <v-expand-transition>
      <v-col
        cols="12"
        v-show="itemExpanded"
        class="expanded-content"
        :class="initializingClass"
      >
        <process-details :process="feedback"></process-details>

        <div class="d-flex align-center pt-4" v-if="canCreateProcess">
          <v-btn
            class="mr-8"
            @click="createNewProcess"
            :loading="processCreating"
          >
            <v-icon class="mr-2">mdi-plus</v-icon>
            {{
              $t('feedbacks.createWithType', {
                processType: $t(
                  `feedbacks.processTypes.${feedback.processType}`
                )
              })
            }}
          </v-btn>
          <span
            v-show="
              $t(`feedbacks.createProcessInfo.${feedback.processType}`) !== ''
            "
          >
            {{ $t(`feedbacks.createProcessInfo.${feedback.processType}`) }}
          </span>
        </div>
      </v-col>
    </v-expand-transition>
  </v-row>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
import { mapActions, mapGetters } from 'vuex';

import processDetails from './process-details.vue';
import processMixins from '@/mixins/feedback-process.js';

export default {
  props: {
    feedback: {
      type: Object,
      required: true
    },

    employeeColumn: {
      type: String,
      required: true
    },

    expanded: {
      type: Boolean,
      required: false,
      default: false
    },

    keyword: {
      type: String,
      required: false,
      default: ''
    }
  },

  mixins: [processMixins],

  data() {
    return {
      itemExpanded: false,
      initializingClass: 'initializing',
      processCreating: false,
      createCorrelationId: null
    };
  },

  computed: {
    ...mapGetters({
      getProcessCreators: 'workflows/getProcessCreators'
    }),

    canCreateProcess() {
      if (!this.feedback.isFinalStatus) {
        return false;
      }

      const creators = this.getProcessCreators(this.feedback.processType);
      if (creators.indexOf(this.feedback.donorRole) >= 0) {
        return true;
      }

      return false;
    },

    expanderIcon() {
      if (this.itemExpanded) {
        return 'mdi-chevron-up';
      } else {
        return 'mdi-chevron-down';
      }
    }
  },

  watch: {
    itemExpanded() {
      if (this.itemExpanded) {
        this.$emit('expanded');
      } else {
        this.$emit('collapsed');
      }
    }
  },

  async mounted() {
    this.createCorrelationId = uuidv4();
    this.itemExpanded = this.expanded;

    // remove the initializing class after a small delay. This
    // way we can disable the transition at the initial load
    // in case the componend is used in the expanded state.
    // Otherwise the initial expanding of the items would
    // already use the transition and that looks weird.
    setTimeout(() => {
      this.initializingClass = '';
    }, 300);
  },

  methods: {
    ...mapActions({
      copyProcess: 'feedbacks/copyProcess'
    }),

    async createNewProcess() {
      this.$feedbacksHub.enterCreateForm(this.createCorrelationId);
      this.$feedbacksHub.$on('feedback-process-created', this.onProcessCreated);

      this.processCreating = true;
      this.copyProcess({
        processId: this.feedback.id,
        correlationId: this.createCorrelationId
      });
    },

    onProcessCreated() {
      this.$emit('process-created');
    }
  },

  beforeDestroy() {
    if (this.createCorrelationId !== null) {
      this.$feedbacksHub.leaveCreateForm(this.createCorrelationId);
      this.$feedbacksHub.$off(
        'feedback-process-created',
        this.onProcessCreated
      );
    }
  },

  components: {
    processDetails
  }
};
</script>

<style lang="scss" scoped>
.overview-body .row .expandable:hover {
  cursor: pointer;
}

.overview-body .row.table-row-divider:hover {
  background: none;
}

.table .row.table-row-divider {
  margin-top: 0;
}

.table .row.table-row-divider > div {
  padding-left: 0;
  padding-right: 0;
  padding: 0;
}

.feedback-row {
  margin-left: -13px;
  margin-right: -13px;
  padding: 0 12px;
}

.bottom-border {
  border-bottom: 16px solid var(--v-background-base);
}

.feedbacks-overview .row:last-of-type {
  border-bottom: none;
}

.theme--light .table .table-body > .row.feedback-row.bottom-border::after {
  border: none;
}

.expanded-content {
  background-color: var(--element-background-secondary);

  // disable all transitions during the initializing phase
  &.initializing {
    transition: none !important;
  }

  .row {
    align-items: center;
  }

  .row:after {
    display: block;
    content: ' ';
    width: 100%;
    margin-left: 1em;
    margin-right: 1em;
    border-bottom: 2px dotted rgba(0, 0, 0, 0.1);
  }

  .row:last-of-type:after {
    border-bottom: none;
  }
}
</style>
